<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <button
      id="print-btn"
      class="btn btn-success"
      @click="handlePrint"
    >
      Print
    </button>
    <!--    <button-->
    <!--      v-b-modal.order-details-->
    <!--      class="btn btn-primary mx-2"-->
    <!--    >-->
    <!--      Order Details-->
    <!--    </button>-->
    <router-link
      :to="`/orders/${order.id}`"
      class="btn btn-primary mx-2"
    >
      Go To Order Details
    </router-link>
    <!--    <order-details-->
    <!--      v-if="Object.keys(order).length > 0"-->
    <!--      :order="order.id"-->
    <!--    />-->
    <div
      v-if="Object.keys(order).length > 0"
      id="invoice-POS"
    >

      <center id="top">
        <div class="logo">
          <img
            :src="require('@/assets/images/logo/logo.svg')"
            width="80"
            height="80"
          >
        </div>
        <div class="info">
          <h1>Wash Capital Laundry</h1>
          <h5 class="font-size"><span>Mobile: +96594194196</span></h5>
          <h5 class="font-size">
            <span>Order Receipt إيصال الطلب</span>
          </h5>
        </div><!--End Info-->
      </center><!--End InvoiceTop-->
      <div id="mid-1">
        <div class="info">
          <!-- <h2>Contact Info</h2> -->
          <p class="info-container font-size">
            <span>
              <strong>رقم الطلب</strong>
              <br>
              <strong>Order ID</strong>
              <br>
              {{ order.id }}
            </span>
            <span>
              <strong>رقم الفاتورة</strong>
              <br>
              <strong>Invoice Number</strong>
              <br>
              #{{ order.invoice_id }}
            </span>
            <span v-if="order.items_add_date">
              <strong>تاريخ الفاتورة</strong>
              <br>
              <strong>Invoice Date</strong>
              <br>
              {{ order.items_add_date }}
            </span>
          </p>
          <p class="font-size">
            <span v-if="order.delivery_time">
              <strong>Delivery Time وقت التسليم</strong>
              <br>
              {{ order.delivery_time.day.week_day }} ({{ order.delivery_time.start_at }} - {{ order.delivery_time.end_at }})
            </span>
          </p>
        </div>
      </div><!--End Invoice Mid-->
      <div id="mid">
        <div class="info">
          <h2 class="font-size">
            <span><strong>Contact Info بيانات التواصل</strong></span>
          </h2>
          <p class="info-container font-size">
            <span v-if="order.user.full_name">
              <strong>Name الاسم</strong>
              <br>
              {{ order.user.full_name }}
            </span>
<!--            <span v-if="order.user.email">-->
<!--              <strong>Email البريد الإلكتروني</strong>-->
<!--              <br>-->
<!--              {{ order.user.email }}-->
<!--            </span>-->
            <span
              v-if="order.user.phone"
              class="phone-box"
            >
              <strong>Phone الهاتف</strong>
              <br>
              {{ order.user.phone }}
            </span>
          </p>
          <p class="font-size">
            <span v-if="fullAddress">
              <strong>Address العنوان</strong>
              <br>
              {{ fullAddress }}
            </span>
          </p>
        </div>
      </div><!--End Invoice Mid-->
      <div
        v-if="user.subscriptions_balance || user.wallet_amount"
        id="mid-1"
      >
        <div class="info">
          <!-- <h2>Info</h2> -->
          <p class="info-container font-size">
            <span v-if="user.subscriptions_balance">
              <strong>Subscriptions Balance رصيد الاشتراكات</strong>
              <br>
              {{ user.subscriptions_balance }} KD
            </span>
            <span v-if="user.wallet_amount">
              <strong>Wallet Amount رصيد المحفظة</strong>
              <br>
              {{ user.wallet_amount }} KD
            </span>
          </p>
        </div>
      </div>
      <div
        v-if="user.preferences"
        id="mid-2"
        class="info-container font-size"
      >
        <span v-if="user.preferences.perfuming">
          <strong>إضافة عطر</strong>
          <br>
          <strong>Add Perfume</strong>
        </span>
        <span v-if="user.preferences.ironing_type">
          <strong>نوع الكي</strong>
          <br>
          <strong>Ironing Type</strong>
          <br>
          {{ user.preferences.ironing_type.name }}
        </span>
        <span v-if="user.preferences.starch_quantity">
          <strong>كمية النشا</strong>
          <br>
          <strong>Starch Quantity</strong>
          <br>
          {{ user.preferences.starch_quantity.name }}
        </span>
        <span v-if="user.preferences.folding_and_hanging_type">
          <strong>الطي والتعليق</strong>
          <br>
          <strong>Folding And Hanging Type</strong>
          <br>
          {{ user.preferences.folding_and_hanging_type.name }}
        </span>
      </div>
      <div
        v-if="user.preferences"
      >
        <div
          v-if="user.preferences.handling_call_me || (user.preferences.handling_call_another_number && user.preferences.handling_phone) || user.preferences.handling_use_door_bill"
          id="mid-2"
        >
          <div class="info">
            <h2 class="font-size">
              <span><strong>Notes For Handling Driver ملاحظات لسواق الاستلام</strong></span>
            </h2>
            <p class="info-container font-size">
              <span v-if="user.preferences.handling_call_me">
                <strong>Call Me اتصل بي</strong>
              </span>
              <span v-if="user.preferences.handling_call_another_number && user.preferences.handling_phone">
                <strong>Call This Number اتصل بهذا الرقم</strong>
                <br>
                {{ user.preferences.handling_phone }}
              </span>
              <span v-if="user.preferences.handling_use_door_bill">
                <strong>Use Door Bill استخدم الجرس</strong>
              </span>
              <span v-if="user.preferences.laundry_bag_door">
                <strong>Laundry bag next to the door كيس الغسيل بجانب الباب</strong>
              </span>
            </p>
          </div>
        </div>
        <div
          v-if="user.preferences.delivery_call_me || (user.preferences.delivery_call_another_number && user.preferences.delivery_phone) || user.preferences.delivery_use_door_bill"
          id="mid-2"
        >
          <div class="info">
            <h2 class="font-size">
              <span><strong>Notes For Delivery Driver ملاحظات لسواق التسليم</strong></span>
            </h2>
            <p class="info-container font-size">
              <span v-if="user.preferences.delivery_call_me">
                <strong>Call Me اتصل بي</strong>
              </span>
              <span v-if="user.preferences.delivery_call_another_number && user.preferences.delivery_phone">
                <strong>Call This Number اتصل بهذا الرقم</strong>
                <br>
                {{ user.preferences.delivery_phone }}
              </span>
              <span v-if="user.preferences.delivery_use_door_bill">
                <strong>Use Door Bill استخدم الجرس</strong>
              </span>
              <span v-if="user.preferences.hanger_behind_the_door">
                <strong>Hanger it behind the door علقها خلف الباب</strong>
              </span>
            </p>
          </div>
        </div>
      </div>
      <!-- <div id="mid">
        <div class="info">
          <h2>Order Info</h2>
          <p>
            <span>   <strong>Notes</strong>   : {{ order.user_note }}</span> <br>
          </p>
        </div>
      </div> -->
      <!--End Invoice Mid-->
      <div id="bot">

        <div id="table">
          <table>
            <tr class="tabletitle table-header">
              <td
                class="item"
                colspan="2"
              >
                <h2 class="font-size">
                  <span>المنتج</span>
                  <br>
                  <span>Item</span>
                  <br>
                </h2>
              </td>
              <td class="Hours">
                <h2 class="font-size">
                  <span>العدد</span>
                  <br>
                  <span>Qty</span>
                  <br>
                </h2>
              </td>
              <td class="Rate">
                <h2 class="font-size">
                  <span>السعر</span>
                  <br>
                  <span>Price</span>
                  <br>
                </h2>
              </td>
            </tr>
            <tbody
              v-for="(service, index) in groupedItems"
              :key="index"
              class="service"
            >
              <tr>
                <td
                  colspan="4"
                  class="tableitem"
                >
                  <span class="itemtext font-size">{{ service[0] }}</span>
                </td>
              </tr>
              <tr
                v-for="(item, i) in service[1]"
                :key="i"
              >
                <td
                  class="tableitem"
                  colspan="2"
                >
                  <span class="itemtext font-size">
                    ({{ item.name }}) {{ item.name_ar }}
                  </span>
                  <span
                    v-if="item.note"
                    class="itemtext noteitem"
                  >
                    <br>
                    <span class="notetext font-size">ملحوظة (Note): {{ item.note }}</span>
                  </span>
                </td>
                <td class="tableitem text-center">
                  <span class="itemtext font-size">
                    {{ item.quantity }}
                  </span>
                </td>
                <td class="tableitem text-center">
                  <span class="itemtext font-size">
                    {{ item.item_price }}
                  </span>
                </td>
              </tr>
            </tbody>
<!--            <tbody-->
<!--              v-for="(item, index) in order.items"-->
<!--              :key="index"-->
<!--              class="service"-->
<!--            >-->
<!--              <tr>-->
<!--                <td class="tableitem">-->
<!--                  <span class="itemtext">-->
<!--                    {{ item.name_ar }}-->
<!--                  </span>-->
<!--                  <br/>-->
<!--                  <span class="itemtext">-->
<!--  &lt;!&ndash;                    {{ item.parent_name }} ({{ item.name }})&ndash;&gt;-->
<!--                    {{ item.name }}-->
<!--                  </span>-->
<!--                </td>-->
<!--                <td class="tableitem text-center">-->
<!--                  <span class="itemtext">-->
<!--                    {{ item.quantity }}-->
<!--                  </span>-->
<!--                </td>-->
<!--                <td class="tableitem text-center">-->
<!--                  <span class="itemtext">-->
<!--                    {{ item.service_type_ar }}-->
<!--                  </span>-->
<!--                  <br>-->
<!--                  <span class="itemtext">-->
<!--                    {{ item.service_type }}-->
<!--                  </span>-->
<!--                </td>-->
<!--                <td class="tableitem text-center">-->
<!--                  <span class="itemtext">-->
<!--                    {{ item.item_price }}-->
<!--                  </span>-->
<!--                </td>-->
<!--              </tr>-->
<!--              <tr-->
<!--                v-if="item.note"-->
<!--              >-->
<!--                <td-->
<!--                  class="tableitem noteitem"-->
<!--                  colspan="4"-->
<!--                >-->
<!--                  <span class="itemtext notetext">-->
<!--                    <span>-->
<!--                      ملحوظة (Note): {{ item.note }}-->
<!--                    </span>-->
<!--                  </span>-->
<!--                  <br>-->
<!--                </td>-->
<!--              </tr>-->
<!--            </tbody>-->
            <tr
              v-if="order.user_note"
            >
              <td
                class="tableitem"
                colspan="4"
              >
                <p class="itemtext font-size">
                  ملحوظة عامة (General Note): {{ order.user_note }}
                </p>
              </td>
            </tr>
            <!--            <tr-->
            <!--              v-if="order.payment_method || (!order.payment_method && +order.remaining_total === 0 && order.paid)"-->
            <!--              class="tabletitle"-->
            <!--            >-->
            <!--              <td class="Rate">-->
            <!--                <h2>Payment Method</h2>-->
            <!--              </td>-->
            <!--              <td></td>-->
            <!--              <td-->
            <!--                class="payment"-->
            <!--                colspan="2"-->
            <!--              >-->
            <!--                <h2 v-if="order.payment_method">-->
            <!--                  {{ order.payment_method.name }}-->
            <!--                </h2>-->
            <!--                <h2 v-else>-->
            <!--                  Subscription-->
            <!--                </h2>-->
            <!--              </td>-->
            <!--            </tr>-->
<!--            <tr class="tabletitle">-->
<!--              <td class="Rate">-->
<!--                <h2>-->
<!--                  <span>عدد المنتجات</span>-->
<!--                  <br>-->
<!--                  <span>Items Number</span>-->
<!--                  <br>-->
<!--                </h2>-->
<!--              </td>-->
<!--              <td></td>-->
<!--              <td-->
<!--                class="payment"-->
<!--                colspan="2"-->
<!--              >-->
<!--                <h2>{{ totalItems }} {{ totalItems === 1 ? 'item' : 'items' }}</h2>-->
<!--              </td>-->
<!--            </tr>-->
            <tr
              class="tabletitle"
            >
              <td class="Rate">
                <h2 class="font-size">
                  <span>Items Total مجموع سعر المنتجات</span>
                </h2>
              </td>
              <td></td>
              <td><span class="font-size">{{ totalItems }}</span></td>
              <td
                class="payment"
                colspan="2"
              >
                <h2 class="font-size">{{ (order.total).toFixed(2) }} KD</h2></td>
            </tr>
            <tr
              v-if="order.type === 'Express'"
              class="tabletitle"
            >
              <td class="Rate">
                <h2 class="font-size">
                  <span>Express Cost تكلفة الخدمة السريعة</span>
                </h2>
              </td>
              <td></td>
              <td></td>
              <td
                class="payment"
                colspan="2"
              >
                <h2 class="font-size">
                  x{{ order.express_cost }}
                </h2>
              </td>
            </tr>
            <tr
              v-if="appliedDiscount"
              class="tabletitle"
            >
              <td class="Rate">
                <h2 class="font-size">
                  <span>Discount الخصم</span>
                </h2>
              </td>
              <td></td>
              <td></td>
              <td
                class="payment"
                colspan="2"
              >
                <h2 class="font-size">{{ appliedDiscount }} KD</h2>
              </td>
            </tr>
            <tr class="tabletitle">
              <td class="Rate">
                <h2 class="font-size">
                  <span>Total المجموع</span>
                </h2>
              </td>
              <td></td>
              <td></td>
              <td
                class="payment"
                colspan="2"
              >
                <h2 class="font-size">{{ (order.grand_total).toFixed(2) }} KD</h2>
              </td>
            </tr>
            <!-- payment details -->
            <tr
              v-if="order.paid_from_wallet"
              class="tabletitle"
            >
              <td class="Rate">
                <h2 class="font-size">
                  <span>From Wallet من المحفظة</span>
                </h2>
              </td>
              <td></td>
              <td></td>
              <td
                class="payment"
                colspan="2"
              >
                <h2 class="font-size">{{ (order.paid_from_wallet).toFixed(2) }} KD</h2>
              </td>
            </tr>
            <tr
              v-if="order.paid_from_upayment"
              class="tabletitle"
            >
              <td class="Rate">
                <h2 class="font-size">
                  <span>From Upayment من يو بايمنت</span>
                </h2>
              </td>
              <td></td>
              <td></td>
              <td
                class="payment"
                colspan="2"
              >
                <h2 class="font-size">{{ (order.paid_from_upayment).toFixed(2) }} KD</h2>
              </td>
            </tr>
            <tr
              v-if="order.paid_by_cash"
              class="tabletitle"
            >
              <td class="Rate">
                <h2 class="font-size">
                  <span>From Cash من الكاش</span>
                </h2>
              </td>
              <td></td>
              <td></td>
              <td
                class="payment"
                colspan="2"
              >
                <h2 class="font-size">{{ (order.paid_by_cash).toFixed(2) }} KD</h2>
              </td>
            </tr>
            <tr
              v-if="order.paid_from_subscription"
              class="tabletitle"
            >
              <td class="Rate">
                <h2 class="font-size">
                  <span>From Subscriptions من الاشتراكات</span>
                </h2>
              </td>
              <td></td>
              <td></td>
              <td
                class="payment"
                colspan="2"
              >
                <h2 class="font-size">{{ (order.paid_from_subscription).toFixed(2) }} KD</h2>
              </td>
            </tr>
            <tr class="tabletitle balance-row">
              <td class="Rate">
                <h2 class="font-size">
                  <span>Balance المستحق</span>
                </h2>
              </td>
              <td></td>
              <td></td>
              <td
                class="payment"
                colspan="2"
              >
                <h2 class="font-size">{{ (order.remain_after_balance_paid).toFixed(2) }} KD</h2>
              </td>
            </tr>
            <!--            <tr-->
            <!--              v-if="order.wallet_deducted_price"-->
            <!--              class="tabletitle"-->
            <!--            >-->
            <!--              <td class="Rate">-->
            <!--                <h2>From Wallet</h2>-->
            <!--              </td>-->
            <!--              <td></td>-->
            <!--              <td-->
            <!--                class="payment"-->
            <!--                colspan="2"-->
            <!--              >-->
            <!--                <h2>{{ (order.wallet_deducted_price).toFixed(2) }} KD</h2>-->
            <!--              </td>-->
            <!--            </tr>-->
            <!--            <tr-->
            <!--              v-if="order.subscription_deducted_price"-->
            <!--              class="tabletitle"-->
            <!--            >-->
            <!--              <td class="Rate">-->
            <!--                <h2>From Subscription</h2>-->
            <!--              </td>-->
            <!--              <td></td>-->
            <!--              <td-->
            <!--                class="payment"-->
            <!--                colspan="2"-->
            <!--              >-->
            <!--                <h2>{{ (order.subscription_deducted_price).toFixed(2) }} KD</h2>-->
            <!--              </td>-->
            <!--            </tr>-->
            <!--            <tr class="tabletitle">-->
            <!--              <td class="Rate">-->
            <!--                <h2>Remaining Total</h2>-->
            <!--              </td>-->
            <!--              <td></td>-->
            <!--              <td-->
            <!--                class="payment"-->
            <!--                colspan="2"-->
            <!--              >-->
            <!--                <h2>{{ (order.remaining_total).toFixed(2) }} KD</h2>-->
            <!--              </td>-->
            <!--            </tr>-->

          </table>
        </div><!--End Table-->

      </div><!--End InvoiceBot-->
      <div
        id="legalcopy"
        class="font-size"
      >
        <span class="font-size">{{ footer }}</span>
      </div>
    </div><!--End Invoice-->
  </div>
</template>
<script>
import { onMounted, ref } from '@vue/composition-api'
import axios from 'axios'
import { Printd } from 'printd'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useOrders from './useOrders'

export default {
  props: {},
  setup(props, context) {
    const toast = useToast()
    const orderId = context.root.$route.params.id
    const order = ref({})
    const appliedDiscount = ref('')
    const fullAddress = ref('')
    const footer = ref('')
    const user = ref('')
    const totalItems = ref(0)
    const groupedItems = ref(new Map())
    const {
      fetchOrder,
    } = useOrders()

    const handlePrint = () => {
      const cssText = `@media print {
          .page-break {
              display: block;
              page-break-before: always;
          }
      }

      /* arabic */
      @font-face {
        font-family: 'El Messiri';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6MoiAAhLz.woff2) format('woff2');
        unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
      }
      /* cyrillic */
      @font-face {
        font-family: 'El Messiri';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6MomAAhLz.woff2) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* latin-ext */
      @font-face {
        font-family: 'El Messiri';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6MoOAAhLz.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'El Messiri';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6Mo2AAg.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* arabic */
      @font-face {
        font-family: 'El Messiri';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6MoiAAhLz.woff2) format('woff2');
        unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
      }
      /* cyrillic */
      @font-face {
        font-family: 'El Messiri';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6MomAAhLz.woff2) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* latin-ext */
      @font-face {
        font-family: 'El Messiri';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6MoOAAhLz.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'El Messiri';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6Mo2AAg.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* arabic */
      @font-face {
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 200;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l_6gHrRpiYlJ.woff2) format('woff2');
        unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
      }
      /* latin */
      @font-face {
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 200;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l_6gHrFpiQ.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* arabic */
      @font-face {
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/tajawal/v9/Iura6YBj_oCad4k1nzSBC45I.woff2) format('woff2');
        unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
      }
      /* latin */
      @font-face {
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/tajawal/v9/Iura6YBj_oCad4k1nzGBCw.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* arabic */
      @font-face {
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l4qkHrRpiYlJ.woff2) format('woff2');
        unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
      }
      /* latin */
      @font-face {
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l4qkHrFpiQ.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* arabic */
      @font-face {
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l7KmHrRpiYlJ.woff2) format('woff2');
        unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
      }
      /* latin */
      @font-face {
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l7KmHrFpiQ.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      #invoice-POS {
          padding: 0 10mm 2mm 0;
          margin: 0 auto;
          width: 100mm;
          background: #FFF;
          font-family: 'arial', serif !important;
      }

      #invoice-POS .font-size {
        font-size: 16px !important;
      }

      #invoice-POS .info-container {
        display: flex;
        justify-content: start;
        word-break: break-word;
      }

      #invoice-POS .info-container span:not(:last-child) {
        border-right: 1px solid #000;
        margin-right: 5px;
        padding-right: 5px;
      }

      #invoice-POS ::selection {
          background: #f31544;
          color: #FFF;
      }

      #invoice-POS ::moz-selection {
          background: #f31544;
          color: #FFF;
      }

      #invoice-POS h1 {
          font-size: 1.5em;
          color: #000;
          margin-bottom: 0px !important;
          margin-top: 0px !important;
      }

      #invoice-POS h2 {
          font-size: 10px;
          margin-bottom: 0px !important;
          margin-top: 0px !important;
      }

      #invoice-POS h3 {
          font-size: 1.2em;
          font-weight: 300;
          line-height: 2em;
          margin-bottom: 0px !important;
          margin-top: 0px !important;
      }

      #invoice-POS h5 {
          margin-bottom: 0px !important;
          margin-top: 0px !important;
      }

      #invoice-POS p {
          font-size: 10px;
          font-weight: bold;
          color: #000;
          line-height: 1.2em;
          margin-bottom: 0 !important;
      }

      #invoice-POS #top, #invoice-POS #mid, #invoice-POS #mid-1, #invoice-POS #mid-2, #invoice-POS #bot, #invoice-POS .table-header {
        border-bottom: 1px dashed #000;
      }

      #invoice-POS #bot {
        padding-top: 0px !important;
      }

      #invoice-POS .balance-row { /* , #invoice-POS .table-header */
        border-top: 1px dashed #000;
      }

      #invoice-POS #top {
          min-height: 80px;
      }

      #invoice-POS #mid {
          min-height: 50px;
      }


      #invoice-POS #bot {
          min-height: 50px;
      }

      #invoice-POS #bot h2 {
          font-size: 10px;
      }

      #invoice-POS #top .logo {
          height: 80px;
          width: 80px;
      }

      #invoice-POS .clientlogo {
          float: left;
          height: 60px;
          width: 60px;
          background: url(http://michaeltruong.ca/images/client.jpg) no-repeat;
          background-size: 60px 60px;
          border-radius: 50px;
      }

      #invoice-POS .info {
          display: block;
          margin-left: 0;
      }

      #invoice-POS .title {
          float: right;
      }

      #invoice-POS .title p {
          text-align: right;
      }

      #invoice-POS table {
          width: 100%;
          border-collapse: collapse;
      }

      #invoice-POS .tabletitle {
          font-size: 10px;
          font-weight: bold;
          margin: 3px;
          background: #EEE;
      }

      #invoice-POS .service {
          border-bottom: 1px solid #000;
      }

      #invoice-POS .item {
          // width: 24mm;
      }

      #invoice-POS .tableitem {
          line-height: 1;
          padding-bottom: 5px;
      }

      #invoice-POS .itemtext {
          font-size: 10px;
          font-weight: bold;
          line-height: 1.6;
      }

      #invoice-POS .noteitem {
          line-height: 1.45;
      }

      #invoice-POS .notetext {
          margin-inline: .5rem;
      }

      #invoice-POS .payment {
          padding: 0 5px;
      }

      #invoice-POS #legalcopy {
          margin-top: 5mm;
          margin-right: 1em;
          margin-left: 1em;
      }

      #invoice-POS .phone-box {
        flex-shrink: 0;
      }`

      const d = new Printd()
      setTimeout(() => {
        d.print(document.getElementById('invoice-POS'), [cssText])
      }, 50)
    }

    onMounted(() => {
      fetchOrder(orderId).then(res => {
        order.value = res

        const itemsServices = new Set(order.value.items.map(item => `${item.service_type} ${item.service_type_ar}`))

        itemsServices.forEach(service => {
          groupedItems.value.set(service, order.value.items.filter(item => `${item.service_type} ${item.service_type_ar}` === service))
        })

        order.value.items.forEach(item => {
          totalItems.value += item.quantity
        })

        if (res.user_discount && res.promo_code) {
          const code = res.promo_code.amount.split(' ')
          const codeAmount = +code[0]
          const codeType = code[1]
          if (codeType === '%') {
            const tempDiscount = Math.max(codeAmount, res.user_discount)
            appliedDiscount.value = `${tempDiscount} %`
          } else {
            const tempTotal = res.total - codeAmount
            if (tempTotal === res.grand_total) {
              appliedDiscount.value = `- ${codeAmount} KD`
            } else {
              appliedDiscount.value = `${res.user_discount} %`
            }
          }
        } else {
          if (res.user_discount) {
            appliedDiscount.value = `${res.user_discount} %`
          }

          if (res.promo_code) {
            appliedDiscount.value = res.promo_code.amount
          }
        }

        if (appliedDiscount.value.includes('%')) {
          const appliedDiscountAmount = +appliedDiscount.value.split(' ')[0]
          appliedDiscount.value = (+res.total * (appliedDiscountAmount / 100)).toFixed(2)
        }

        if (res.delivery_address) {
          const { area } = res.delivery_address
          // const city = area.city ?? null
          // if (city) fullAddress.value += ` City: ${city.name}, `
          if (area) fullAddress.value += ` Area: ${area.name}, `
          if (res.delivery_address.block) fullAddress.value += ` Block: ${res.delivery_address.block}, `
          if (res.delivery_address.avenue) fullAddress.value += ` Avenue: ${res.delivery_address.avenue}, `
          if (res.delivery_address.street) fullAddress.value += ` Street: ${res.delivery_address.street}, `
          if (res.delivery_address.building) fullAddress.value += ` Building: ${res.delivery_address.building}, `
          if (res.delivery_address.floor_number) fullAddress.value += ` Floor_number: ${res.delivery_address.floor_number}, `
          if (res.delivery_address.apartment_number) fullAddress.value += ` Apartment Number: ${res.delivery_address.apartment_number}, `
          if (res.delivery_address.landmark) fullAddress.value += ` Landmark: ${res.delivery_address.landmark}, `
          if (res.delivery_address.details) fullAddress.value += ` Details: ${res.delivery_address.details}, `
        }

        axios.get(`${process.env.VUE_APP_BASE_API_URL}/pos/users/${res.user.id}`, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        }).then(userRes => {
          if (userRes.data.code === 200) {
            user.value = userRes.data.data.user

            axios.get(`${process.env.VUE_APP_BASE_API_URL}/configurations?scope=3`)
              .then(result => {
                if (result.data.code === 200) {
                  footer.value = result.data.data.configurations.pos_print_footer
                  document.getElementById('legalcopy').innerHTML = result.data.data.configurations.pos_print_footer
                  handlePrint()
                }
              })
              .catch(() => {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error fetching configurations list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching user',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      })
    })

    return {
      order,
      totalItems,
      user,
      fullAddress,
      appliedDiscount,
      footer,
      handlePrint,
      groupedItems,
    }
  },
}
</script>
<style scoped>
@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}

#invoice-POS {
  box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
  padding: 0 2mm;
  margin: 0 auto;
  width: 77mm;
  background: #FFF;
  font-family: 'arial', serif !important;
}

#invoice-POS ::selection {
  background: #f31544;
  color: #FFF;
}

#invoice-POS ::moz-selection {
  background: #f31544;
  color: #FFF;
}

#invoice-POS h1 {
  font-size: 1.5em;
  color: #222;
}

#invoice-POS h2 {
  font-size: 10px;
}

#invoice-POS h3 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}

#invoice-POS p {
  font-size: 10px;
  font-weight: bold;
  color: #666;
  line-height: 1.2em;
}

#invoice-POS #top, #invoice-POS #mid, #invoice-POS #mid-1, #invoice-POS #mid-2, #invoice-POS #bot, #invoice-POS .table-header {
  border-bottom: 1px dashed #000;
  /*padding-top: 10px;*/
}

#invoice-POS #bot {
  padding-top: 0px !important;
}

#invoice-POS .balance-row { /* , #invoice-POS .table-header */
  border-top: 1px dashed #000;
}

#invoice-POS #top {
  min-height: 80px;
}

#invoice-POS #mid {
  min-height: 50px;
}

/*#invoice-POS #mid-1 {*/
/*  min-height: 30px;*/
/*}*/

#invoice-POS #bot {
  min-height: 50px;
}

#invoice-POS #bot h2 {
  font-size: 10px;
}

#invoice-POS #top .logo {
  height: 80px;
  width: 80px;
}

#invoice-POS .clientlogo {
  float: left;
  height: 60px;
  width: 60px;
  background: url(http://michaeltruong.ca/images/client.jpg) no-repeat;
  background-size: 60px 60px;
  border-radius: 50px;
}

#invoice-POS .info {
  display: block;
  margin-left: 0;
}

#invoice-POS .info-container {
  display: flex;
  justify-content: start;
  word-break: break-word;
}

#invoice-POS .info-container span:not(:last-child) {
  border-right: 1px solid #000;
  margin-right: 5px;
  padding-right: 5px;
}

#invoice-POS h1 {
  font-size: 1.5em;
  color: #000;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

#invoice-POS h2 {
  font-size: 10px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

#invoice-POS h3 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

#invoice-POS h5 {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

#invoice-POS .title {
  float: right;
}

#invoice-POS .title p {
  text-align: right;
}

#invoice-POS table {
  width: 100%;
  border-collapse: collapse;
}

#invoice-POS .tabletitle {
  font-size: 10px;
  font-weight: bold;
  margin: 3px;
  background: #EEE;
}

#invoice-POS .service {
  border-bottom: 1px solid #000;
  /*padding-top: 10px !important;*/
  /*padding-bottom: 10px !important;*/
}

#invoice-POS #mid-2 {
  /*padding-top: 10px !important;*/
}

#invoice-POS .item {
  /*width: 24mm;*/
}

#invoice-POS .tableitem {
  line-height: 0.8;
  padding-bottom: 5px;
}

#invoice-POS .itemtext {
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
}

#invoice-POS .noteitem {
  line-height: 1.45;
}

#invoice-POS .notetext {
  margin-inline: .5rem;
}

#invoice-POS #legalcopy {
  font-size: 0.5em;
  margin-top: 5mm;
  color: #666;
}

#invoice-POS h2 {
  color: #666;
}

#invoice-POS span {
  color: #666;
}

#invoice-POS .font-size {
  font-size: 11px !important;
}

#invoice-POS .phone-box {
  flex-shrink: 0;
}
</style>
